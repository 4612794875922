<template>
  <v-app>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="60" class="text-center">
        Loading please wait
      </v-progress-circular>
    </v-overlay>
    <v-app-bar
      flat
      color="bodyBgColor"
      app
      fixed
      clipped-left
      class="px-3"
      height="78"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="d-flex align-center pl-0">
        <v-img
          class="mx-0"
          src="@/assets/logo_xsmall.png"
          contain
          max-height="40"
          max-width="60"
        ></v-img>
        <router-link :to="{ name: 'Home' }" class="toolbar-link mt-2">
          Cool Teacher
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        v-if="!premium"
        color="warning"
        depressed
        outlined
        small
        link
        to="my-subscription"
      >
        <v-icon left>mdi-star-shooting-outline</v-icon>
        Get Premium
      </v-btn>
      <v-btn
        v-else
        color="success"
        depressed
        outlined
        small
        link
        to="my-subscription"
      >
        <v-icon left>mdi-star-shooting-outline</v-icon>
        Premium
      </v-btn>

      <v-menu
        offset-y
        offset-x
        v-model="accountMenu"
        v-if="currUser"
        min-width="240"
      >
        <template v-slot:activator="{ attrs }">
          <v-tooltip
            bottom
            max-width="220"
            open-delay="500"
            :disabled="accountMenu"
            v-bind="attrs"
          >
            <template v-slot:activator="{ attrs }">
              <v-btn
                v-if="currUser"
                icon
                x-large
                class="ml-2"
                v-bind="attrs"
                v-on:click="accountMenu = !accountMenu"
              >
                <v-avatar color="brown" size="48">
                  <span class="white--text text-h5">{{ userInitials }}</span>
                </v-avatar>
              </v-btn>
            </template>
            <span>
              {{ currUser.displayName }}
              <br />
              {{ currUser.email }}
            </span>
          </v-tooltip>
        </template>
        <v-card>
          <v-list-item>
            <div class="justify-center pb-0">
              <div class="mx-auto text-center">
                <v-avatar color="brown">
                  <span class="white--text text-h5">{{ userInitials }}</span>
                </v-avatar>
                <h3>{{ currUser.displayName }}</h3>
                <p class="text-caption mt-1">
                  {{ currUser.email }}
                </p>
              </div>
            </div>
          </v-list-item>
          <v-divider></v-divider>
          <v-list dense>
            <v-list-item link class="pl-2" @click="logOut()">
              <v-icon class="mr-2">
                <v-icon>mdi-logout</v-icon>
              </v-icon>

              <div>
                <v-list-item-title>Logout</v-list-item-title>
              </div>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer color="bodyBgColor" v-model="drawer" app clipped>
      <v-layout justify-space-between column fill-height>
        <v-list shaped>
          <v-list-group>
            <v-list-item link to="new-resource">
              <v-icon class="mx-3">
                <v-icon> mdi-folder-plus-outline</v-icon>
              </v-icon>
              <div>
                <v-list-item-title>New Resource</v-list-item-title>
              </div>
            </v-list-item>
            <v-list-item link to="my-resources">
              <v-icon class="mx-3">
                <v-icon> mdi-folder-open-outline</v-icon>
              </v-icon>
              <div>
                <v-list-item-title>My Resources</v-list-item-title>
              </div>
            </v-list-item>
            <v-list-item link to="my-subscription">
              <v-icon class="mx-3">
                <v-icon> mdi-star-shooting-outline</v-icon>
              </v-icon>
              <div>
                <v-list-item-title>My Subscription</v-list-item-title>
              </div>
            </v-list-item>
            <v-list-item link to="contact-support">
              <v-icon class="mx-3">
                <v-icon> mdi-headset</v-icon>
              </v-icon>
              <div>
                <v-list-item-title>Contact Support</v-list-item-title>
              </div>
            </v-list-item>
          </v-list-group>
        </v-list>

        <v-tooltip top open-delay="200">
          <template v-slot:activator="{ attrs }">
            <v-btn
              outlined
              small
              fab
              v-on:click="changeTheme"
              class="ml-8 mb-3"
              v-bind="attrs"
            >
              <v-icon size="28">{{
                darkTheme ? "mdi-weather-sunny" : "mdi-weather-night"
              }}</v-icon>
            </v-btn>
          </template>
          <span v-if="darkTheme">Use light theme</span>
          <span v-else>Use dark theme</span>
        </v-tooltip>
      </v-layout>
    </v-navigation-drawer>

    <v-main class="v-main--custom">
      <v-container fluid fill-height class="v-container--custom bodyBgColor">
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app padless fixed>
      <v-col class="text-center" cols="12">
        Please understand that, similar to all ai systems, CoolTeacher have
        limitations and may produce inaccurate information
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { signOut } from "firebase/auth";
import { firebaseAuth, db } from "@/firebase";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

export default {
  data: () => ({
    submenuOpen: false,
    isLoading: false,
    drawer: null,
    //  darkTheme: false,
    accountMenu: false,
    currUser: firebaseAuth.currentUser,
  }),
  computed: {
    premium() {
      return this.$store.state.subscription
        ? this.$store.state.subscription.status == "active" ||
            this.$store.state.subscription.status == "active school" ||
            this.$store.state.subscription.status == "trialing"
        : false;
    },
    userInitials() {
      const currUserNameSplit = this.currUser.displayName.split(" ");
      return currUserNameSplit[0][0] + currUserNameSplit[1][0];
    },
    darkTheme() {
      return this.$store.getters["switchTheme/isDarkTheme"];
    },
  },
  async created() {
    this.isLoading = true;
    this.$store.commit("updatesubcribtion", null);
    const dbs = getFirestore();
    const subsRef = collection(
      dbs,
      "customers",
      firebaseAuth.currentUser.uid,
      "subscriptions"
    );

    let subsQuery = query(
      subsRef,
      where("status", "in", ["trialing", "active"])
    );

    await getDocs(subsQuery).then((sub) => {
      if (sub.docs.length > 0) {
        this.$store.commit("updatesubcribtion", sub.docs[0].data());
      } else {
        try {
          db.collection("schoollicence")
            .get()
            .then((res) => {
              let found = false;
              let expire = "";
              let schoolname = "";

              res.forEach((doc) => {
                if (doc.data().domain) {
                  if (
                    doc.data().domain ==
                    firebaseAuth.currentUser.email.split("@")[1]
                  ) {
                    found = true;
                    expire = doc.data().expiration;
                    schoolname = doc.data().schoolname;
                  }
                } else if (doc.data().users) {
                  if (
                    doc.data().users.includes(firebaseAuth.currentUser.email)
                  ) {
                    found = true;
                    expire = doc.data().expiration;
                    schoolname = doc.data().schoolname;
                  }
                }
              });
              if (found) {
                this.$store.commit("updatesubcribtion", {
                  status: "active school",
                  current_period_end: expire,
                  school_name: schoolname,
                });
              } else {
                this.$store.commit("updatesubcribtion", {
                  status: "not subscribed yet",
                });
              }
            });
        } catch (error) {
          // Error
        }
      }
    });
    this.isLoading = false;
  },
  methods: {
    logOut() {
      signOut(firebaseAuth).then(() =>
        this.$router.replace({ name: "Landing" })
      );
    },
    changeTheme() {
      this.$store.commit("switchTheme/changeTheme");
      this.$vuetify.theme.dark = this.darkTheme;
    },
  },
};
</script>
<style scoped>
.v-main--custom {
  position: fixed;
  width: 100%;
  height: 100%;
}
.v-main--custom .v-container--custom {
  padding: 0 56px 20px 56px;
}
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.dark-theme {
  background-color: #c0b9b9;
  color: #fff;
}
.ligh-theme {
  background-color: #fff;
  color: #7a7676;
}
.v-navigation-drawer:deep(.v-navigation-drawer__border) {
  display: none;
}
.toolbar-link {
  /* Add your desired styles here */
  color: inherit;
  text-decoration: none;
}
</style>
