<template>
  <v-app>
    <component v-bind:is="layout"></component>
  </v-app>
</template>

<script>
import EmptyLayout from "./layouts/EmptyLayout.vue";

export default {
  name: "App",
  computed: {
    layout() {
      return this.$route.meta.layout || EmptyLayout;
    },
  },
  created() {
    this.$store.commit("switchTheme/setCurrentTheme");
    this.$vuetify.theme.dark = this.$store.getters["switchTheme/isDarkTheme"];
  },
};
</script>

<style>
/* width */
body::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b8b9c3;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #80868b;
}
</style>
