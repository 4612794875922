import getters from "./getters.js";
import mutations from "./mutations.js";

export default {
  namespaced: true,
  state() {
    return {
      darkTheme: false,
    };
  },
  getters,
  mutations,
};
