// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  theme: {
    themes: {
      light: {
        bodyBgColor: "#ffffff",
        mainContainerBgColor: "#f3f6fc",
        info: "#0B57D0",
      },
      dark: {
        bodyBgColor: "#000000",
        mainContainerBgColor: "#222327",
        info: "#A6C5F7",
      },
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
