import { createStore } from "vuex";
import switchDarkLightMode from "./modules/switchDarkLightMode/index.js";

export default createStore({
  state: {
    subscription: {},
    edited: {},
  },
  getters: {},
  mutations: {
    updatesubcribtion(state, payload) {
      state.subscription = payload;
    },
    updateedited(state, payload) {
      state.edited = { ...payload };
    },
  },
  actions: {},
  modules: {
    switchTheme: switchDarkLightMode,
  },
});
