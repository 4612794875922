import { createRouter, createWebHistory } from "vue-router";
import { getCurrentUser } from "@/firebase";

import NewFace from "../layouts/NewFace.vue";

const routes = [
  {
    path: "/",
    name: "Landing",
    meta: { guestOnly: true },
    component: () => import("../views/LandingView.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    meta: { guestOnly: true },
    component: () => import("../views/PrivacyPolicy.vue"),
  },

  // routes to home page links
  {
    path: "/home",
    name: "Home",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/new-resource",
    name: "NewResource",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/newface/NewResource.vue"),
  },
  {
    path: "/my-resources",
    name: "MyResources",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/newface/MyResources.vue"),
  },
  {
    path: "/my-subscription",
    name: "MySubscription",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/newface/MySubscription.vue"),
  },
  {
    path: "/contact-support",
    name: "ContactSupport",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/newface/ContactSupport.vue"),
  },

  // inner link routes
  {
    path: "/editor",
    name: "Editor",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/newface/TinymceEditor.vue"),
  },

  // routes to new resource creation (apps) pages
  {
    path: "/quiz-craft",
    name: "QuizCraft",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/apps/QuizCraft.vue"),
  },
  {
    path: "/lesson-planner",
    name: "LessonPlanner",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/apps/LessonPlanner.vue"),
  },
  {
    path: "/lesson-launch",
    name: "LessonLaunch",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/apps/LessonLaunch.vue"),
  },
  {
    path: "/tale-forge",
    name: "TaleForge",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/apps/TaleForge.vue"),
  },
  {
    path: "/edu-script",
    name: "EduScript",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/apps/EduScript.vue"),
  },
  {
    path: "/class-clown",
    name: "ClassClown",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/apps/ClassClown.vue"),
  },
  {
    path: "/dictionary",
    name: "dictionary",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/apps/DictionAry.vue"),
  },

  {
    path: "/intervene-expert",
    name: "IntervenExpert",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/apps/IntervenExpert.vue"),
  },
  {
    path: "/parent-connect",
    name: "ParentConnect",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/apps/ParentConnect.vue"),
  },
  {
    path: "/translate-pro",
    name: "TranslatePro",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/apps/TranslatePro.vue"),
  },

  {
    path: "/email-composer",
    name: "EmailComposer",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/apps/EmailComposer.vue"),
  },
  {
    path: "/recommend-me",
    name: "RecommendMe",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/apps/RecomendMe.vue"),
  },
  {
    path: "/college-4-all",
    name: "College4All",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/apps/College4All.vue"),
  },
  {
    path: "/non-fiction",
    name: "NonFiction",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/apps/NonFiction.vue"),
  },
  {
    path: "/story-reflect",
    name: "StoryReflect",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/apps/StoryReflect.vue"),
  },
  {
    path: "/gamify",
    name: "gamify",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/apps/GamiFy.vue"),
  },
  {
    path: "/story-problems",
    name: "StoryProblems",
    meta: { requiresAuth: true, layout: NewFace },
    component: () => import("../components/apps/StoryProblems.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isGuestOnly = to.matched.some((record) => record.meta.guestOnly);

  const currentUser = await getCurrentUser();

  if (requiresAuth && !currentUser) {
    next("/");
  } else if (isGuestOnly && currentUser) {
    next("/home");
  } else {
    next();
  }
});

export default router;
