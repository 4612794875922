export default {
  changeTheme(state) {
    state.darkTheme = !state.darkTheme;
    localStorage.setItem("currentTheme", state.darkTheme ? "dark" : "light");
  },
  setCurrentTheme(state) {
    const currentTheme = localStorage.getItem("currentTheme");
    state.darkTheme = currentTheme === "dark";
  },
};
